@import '../variables';

.lefty-list {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: $list-padding;

  div[group]:not(:first-child) {
    margin-top: $grid-space-3 !important;
    padding: 0 !important;
    border-top: none !important;
  }

  div[group] div[label] {
    @include app-body-1;
    line-height: 1;
    color: $grey-600;
    box-sizing: border-box;
    padding: 0 $list-item-horizontal-padding;
    padding-bottom: $grid-space-2;

    display: flex;
    align-items: flex-end;
    height: 47px;
  }
}

.lefty-list-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  white-space: nowrap;

  min-height: $list-item-height;
  padding: $list-item-padding;

  transition:
    color 300ms,
    background-color 300ms;

  @include app-body-1;

  a,
  a:hover,
  a:visited {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: inherit;
  }

  &:hover,
  &:focus,
  &.active {
    background-color: $list-item-hover-bckg-color;
    cursor: pointer;
  }

  &.selectable-item {
    &:not(.multiselect).selected {
      background-color: $list-item-selected-bckg-color;
      color: $list-item-selected-font-color;

      &:hover {
        background-color: $list-item-selected-bckg-color;
      }
    }

    .dynamic-item {
      flex-grow: 1;
    }
  }

  &:disabled,
  &.disabled {
    color: $list-item-disabled-font-color;
    cursor: not-allowed;

    &.left-icon,
    &.leading-icon {
      lefty-icon {
        color: $list-item-disabled-font-color;
      }
    }
  }

  &.left-icon,
  &.leading-icon {
    lefty-icon {
      color: $grey-600;
      margin-right: $grid-space-2;
    }
  }

  &.danger {
    color: $danger-color !important;
  }
}

// TODO: lefty-dropdown-select-item
