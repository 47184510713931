/* You can add global styles to this file, and also import other style files */

@import '../../../packages/ui/src/lib/date/style.scss';
@import 'cal-heatmap/cal-heatmap.css';
@import '../../../packages/lefty-assets/src/lib/css/cal-heatmap.scss';

@font-face {
  font-family: 'ProximaNova Regular';
  font-display: swap;
  src: url('../../../packages/lefty-assets/src/lib/fonts/proximanova/ProximaNova-Regular.otf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Bold';
  font-display: swap;
  src: url('../../../packages/lefty-assets/src/lib/fonts/proximanova/ProximaNova-Bold.otf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Light';
  font-display: swap;
  src: url('../../../packages/lefty-assets/src/lib/fonts/proximanova/ProximaNova-Light.otf')
    format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Italic';
  font-display: swap;
  src: url('../../../packages/lefty-assets/src/lib/fonts/proximanova/ProximaNova-RegularIt.otf')
    format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'ProximaNova Semi Bold';
  font-display: swap;
  src: url('../../../packages/lefty-assets/src/lib/fonts/proximanova/ProximaNova-Semibold.otf')
    format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Butler-Black';
  font-display: swap;
  src: url('../../../packages/lefty-assets/src/lib/fonts/butler/Butler_Black.otf')
    format('truetype');
  font-weight: 600;
}

body {
  background-color: white;
}
