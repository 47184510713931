@import './grid';
@import './colors';

@mixin lefty-feedback-variables {
  --feedback-padding: #{$grid-space-3};
  --feedback-border-radius: 4px;

  --feedback-icon-color: var(--black);
  --feedback-info-icon-color: var(--feedback-icon-color);
  --feedback-warning-icon-color: var(--feedback-icon-color);
  --feedback-error-icon-color: var(--feedback-icon-color);
  --feedback-unavailable-icon-color: var(--feedback-icon-color);
  --feedback-success-icon-color: var(--feedback-icon-color);

  --feedback-info-color: #e4f5f6;
  --feedback-warning-color: #fdf5e0;
  --feedback-error-color: #f9ebe9;
  --feedback-success-color: #f2f6e6;
  --feedback-unavailable-color: var(--grey-050);

  --feedback-info-icon: info_feedback_outline;
  --feedback-warning-icon: info_feedback_outline;
  --feedback-error-icon: error_feedback_outline;
  --feedback-unavailable-icon: error_feedback_outline;
  --feedback-success-icon: success_feedback_outline;
}
