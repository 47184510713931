$shadow-200: var(--shadow-200);
$shadow-500: var(--shadow-500);

$base-shadow: $shadow-200;

/// old
$box-shadow-dark: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
$box-shadow-medium: 0 5px 20px 0 rgba(0, 0, 0, 0.08);
$box-shadow-light: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
$box-shadow-blur: 0 0 10px 0;
///

@mixin lefty-shadow-variables {
  --shadow-200: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px 3px 10px 0px rgba(0, 0, 0, 0.08);
  --shadow-500: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14);
}
