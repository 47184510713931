@import './fonts';
@import './grid';

@mixin lefty-table-variables {
  --table-background-color: white;
  --table-header-border-color: var(--grey-080);
  --table-header-height: 44px;

  --table-header-cell-padding-top: #{$grid-space-2};
  --table-header-cell-padding-bottom: #{$grid-space-2};

  --table-padding-left: #{$grid-space-2};
  --table-padding-right: #{$grid-space-2};

  --table-selectable-padding-left: #{$grid-space-4};
  --table-selectable-padding-right: #{$grid-space-4};

  --table-cell-border-color: var(--grey-080);
  --table-cell-padding-left: #{$grid-space-2};
  --table-cell-padding-right: #{$grid-space-7};
  --table-cell-padding-top: #{$grid-space-3};
  --table-cell-padding-bottom: #{$grid-space-3};

  --table-row-active-color: rgba(230, 248, 250, 0.5);
  --table-row-selected-color: var(--grey-020);

  --table-footer-background-color: white;
  --table-footer-border-color: var(--grey-080);
}
