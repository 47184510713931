@import 'variables';
@import 'mixins';

.ch-subdomain-bg {
  cursor: pointer;
}

.ch-domain-text {
  @include app-caption-1;
}
