@use 'sass:math';

@import './variables';
@import 'grid/gridlex.scss';
@import './components/animate';
@import './components/card';
@import './components/layout';
@import './components/forms';
@import './components/buttons';
@import './components/typo';
@import './components/select';
@import './components/chips';
@import './components/list';
@import './components/badge';
@import './components/tooltip';
@import './components/slider';
@import './components/spinner';
@import './components/progress';
@import './components/icon';
@import './components/checkbox';
@import './components/dialog';
@import './components/radio';
@import './components/feedback';
@import './components/table';

:root {
  @include lefty-variables;
}

body {
  @include lefty-scrollbar;

  font-family: var(--default-font), Roboto, Arial, sans-serif;

  margin: 0;
  background-color: $grey-020;
  line-height: rem($default-line-height);

  &.no-animation * {
    animation: none !important;
    transition: none !important;
  }

  &.white {
    background-color: white;
  }

  &.april-fools {
    .al-card {
      animation: shake;
      animation-duration: 5s;
      animation-iteration-count: 3;
    }
  }

  // When app is inside iframe
  &.lefty-app-iframe {
    // Is on background route, meaning the app iframe is not visible
    // but the active route is AlfredIframeBackgroundRouteComponent
    &.background-route {
      // If a dialog is open inside iframe
      // we make the background transparent,
      // so the parent APP stay visible behind the dialog
      //
      // The parent APP is also notified and make the iframe visible AND on top of everything
      &.dialog-visible {
        background-color: transparent;
      }
    }
  }
}

*:focus {
  outline: none !important;
}

.no-margin {
  margin: 0;
}

.display-none {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}

.overflow-hidden {
  overflow: hidden;
}

@keyframes ghost-animation {
  0% {
    background-position: -100px;
  }

  40% {
    background-position: 40vw;
  }

  100% {
    background-position: 60vw;
  }
}

[class^='icon-']:before,
[class*=' icon-']:before {
  &.x-small {
    font-size: rem(12px);
  }

  &.small {
    font-size: rem(14px);
  }

  &.medium {
    font-size: rem(16px);
  }

  &.large {
    font-size: rem(18px);
  }

  &.x-large {
    font-size: rem(20px);
  }
}

.sidebar-divider {
  height: 1px;
  background: $grey-080;
  margin-top: $grid-space-5;
  margin-bottom: $grid-space-5;
  margin-left: -$grid-space-4;
  margin-right: -$grid-space-4;
}

@media print {
  .hide-print {
    display: none !important;
  }

  button,
  .button,
  a {
    display: none !important;
  }
}

.lefty-tag,
.feature-tag {
  text-transform: capitalize;
  font-size: rem(12px);
  line-height: 1;

  display: inline-flex;
  align-items: center;

  border-radius: 2px;
  padding: 0 $grid-space-1;
  height: 18px;

  background-color: $teal-050;
  color: $teal-500;
}

.feature-tag {
  &.new {
    &::before {
      content: 'New';
    }
  }

  &.beta {
    &::before {
      content: 'Beta';
    }
  }

  &.admin {
    background-color: $red-050;
    color: $red-400;

    &::before {
      content: 'Admin';
    }
  }
}

.al-popup {
  .loading-container,
  .empty-container {
    margin: 16px;
  }

  .empty-container {
    @include app-caption-1();
  }
}

.pane.selections {
  .empty {
    @include app-caption-1();
    font-style: normal;
  }
}

button.filters-button {
  .count {
    margin-left: $grid-space-1;
    color: $primary-color;
  }

  &.visible,
  &.active {
    background-color: $primary-color-050;
    color: $primary-color;

    &:focus {
      background-color: $primary-color-050;
    }

    lefty-icon {
      color: $primary-color !important;
    }
  }
}

.filters-container {
  position: relative;
  z-index: -1;
  height: 0;
  opacity: 0;

  &.visible {
    z-index: 2;
    opacity: 1;
    transition: all 300ms;

    transition: all 200ms;
    padding-top: $grid-space-2;
    padding-bottom: $grid-space-5;
  }

  .filter {
    width: 100%;
  }

  .clear {
    margin-left: $grid-space-1;
    margin-bottom: $grid-space-2;
  }
}

.col-table-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  flex-basis: percentage(math.div(1, 6));
  min-width: percentage(math.div(1, 6));
}

.col-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  flex-basis: percentage(math.div(1, 5));
  min-width: percentage(math.div(1, 5));
}

@include bp('lg') {
  .col-table-filter {
    flex-basis: percentage(math.div(1, 5));
    min-width: percentage(math.div(1, 5));
  }

  .col-filter {
    flex-basis: percentage(math.div(1, 4));
    min-width: percentage(math.div(1, 4));
  }
}

@include bp('md') {
  .col-table-filter,
  .col-filter {
    flex-basis: percentage(math.div(1, 3));
    min-width: percentage(math.div(1, 3));
  }
}

.scrollbar-on-hover {
  @include lefty-scrollbar-on-hover;
}

$_rfm-a-color: $green-800;

// custom color, out of design system
$_rfm-b-color: #82c91e;

$_rfm-c-color: $yellow-500;
$_rfm-d-color: $orange-500;
$_rfm-e-color: $red-400;
$_rfm-inactive-color: $grey-400;

.rfm-score-badge {
  display: flex;
  align-items: center;
  justify-content: center;

  @include app-heading-4;
  line-height: 1;
  color: white;
  text-transform: uppercase;

  height: 24px;
  width: 24px;
  border-radius: 4px;

  &.score-A {
    background-color: $_rfm-a-color;
    color: white;
  }

  &.score-B {
    background-color: $_rfm-b-color;
    color: white;
  }

  &.score-C {
    background-color: $_rfm-c-color;
    color: white;
  }

  &.score-D {
    background-color: $_rfm-d-color;
    color: white;
  }

  &.score-E {
    background-color: $_rfm-e-color;
    color: white;
  }

  &.score-inactive {
    background-color: $_rfm-inactive-color;
    color: white;
  }
}

cdk-virtual-scroll-viewport.allow-popup-inside {
  .cdk-virtual-scroll-content-wrapper {
    contain: layout style;
  }
}
