@import '../variables';

// Scrollbar style inspired from Material Design but with lefty style
//
// use !important everywhere because we need to override material design default style
// can be remove once we get ride of all material design components
//////////

@mixin lefty-scrollbar {
  ::-webkit-scrollbar {
    background-color: $scrollbar-background-color !important;

    // Height only applies to horizontal scrollbars.
    height: $scrollbar-size !important;

    // Width only applies to vertical scrollbars.
    width: $scrollbar-size !important;

    &:hover {
      background-color: $scrollbar-background-color-hover !important;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color !important;

    // min-height only applies to vertical scrollbar thumbs.
    min-height: $scrollbar-thumb-min-size !important;

    // min-width only applies to horizontal scrollbar thumbs.
    min-width: $scrollbar-thumb-min-size !important;

    border-radius: $scrollbar-thumb-radius;
    border: solid $scrollbar-background-color $scrollbar-thumb-border;

    &:hover {
      background-color: $scrollbar-thumb-color-hover !important;
      border: solid $scrollbar-background-color-hover $scrollbar-thumb-border;
    }
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
}

@mixin lefty-scrollbar-on-hover {
  &:not(:hover) {
    &::-webkit-scrollbar {
      background-color: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
}
