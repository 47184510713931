@use 'sass:math';

@import '../variables';
@import '../mixins';

.lefty-slider {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: $slider-thumb-unactive-size;
  cursor: pointer;

  &.is-disabled {
    cursor: not-allowed;

    .thumb,
    .thumb:focus,
    .thumb:hover {
      outline: none;
      border: none;
      background-color: $slider-thumb-disabled-color;
    }

    .left-track-container > .track {
      background-color: $slider-unset-color;
    }

    .right-track-container > .track {
      background-color: $slider-unset-color;
    }
  }

  .track-container {
    align-items: center;
    display: flex;
    height: $slider-bar-height;
  }

  .track {
    height: 100%;
    width: 100%;
  }

  .double-sided-left-track-container > .track {
    background-color: $slider-unset-color;
  }

  .left-track-container > .track {
    background-color: $slider-color;
  }

  .right-track-container > .track {
    background-color: $slider-unset-color;
  }

  .thumb {
    height: $slider-thumb-unactive-size;
    width: $slider-thumb-unactive-size;
    border-radius: 50%;
    background-color: $slider-color;
    position: relative;

    transition: transform 300ms;

    &:focus,
    &:hover {
      transform: scale(
        math.div($slider-thumb-active-size, $slider-thumb-unactive-size)
      );

      outline: $slider-outline-size solid $slider-outline-color;
      border: solid 1px white;
    }
  }
}
