@use 'sass:math';

@import '../variables';
@import '../mixins';

.lefty-radio {
  align-items: baseline;
  cursor: pointer;
  display: inline-flex;

  > .icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    box-sizing: border-box;

    border: 1px solid $grey-180;
    border-radius: 10px;

    .round {
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background-color: transparent;
    }
  }

  &:focus,
  &.focus {
    > .icon-container {
      outline-offset: 1px;
      outline: solid 2px $primary-color-300;
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;

    > .icon-container {
      border-color: $grey-180;

      .round {
        background-color: $grey-180;
      }
    }

    &:not(.checked) {
      > .icon-container {
        background-color: $grey-180;
      }
    }
  }

  &.checked {
    > .icon-container {
      border-color: $primary-color;

      .round {
        background-color: $primary-color;
      }
    }
  }

  > .content {
    align-items: center;
    flex: 1;

    @include app-body-1;
    color: $grey-800;
    margin: 0 $grid-space-1;
    overflow: hidden;
  }
}

.lefty-radio-group {
  display: flex;
  align-items: flex-start;

  &.outline {
    flex-direction: column;

    .lefty-radio {
      margin: 0;
      padding: $grid-space-4;
      border-radius: 8px;
      border: solid 1px $grey-080;

      width: 100%;
      box-sizing: border-box;

      &:first-child {
        margin-top: $grid-space-2;
      }

      &:not(:last-child) {
        margin-bottom: $grid-space-2;
      }

      .helper {
        margin-top: $grid-space-2;
        color: $grey-600;
      }

      > .content {
        margin: 0 $grid-space-2;
        overflow: visible;
      }
    }
  }
}
