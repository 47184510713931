@import './grid';
@import './colors';

@mixin lefty-button-variables {
  --button-font-size: #{rem(14px)};
  --button-font-family: var(--font-regular);
  --button-font-weight: 400;
  --button-line-height: 1;
  --button-border-size: 1px;
  --button-focus-color: var(--primary-color-300);
  --button-focus-border-size: 2px;

  --button-height: 32px;
  --button-padding: 0 #{$grid-space-3};
  --button-border-radius: 4px;
  --button-icon-size: 20px;
  --button-spinner-size: 15px;

  --button-small-height: 24px;
  --button-small-padding: 0 #{$grid-space-2};
  --button-small-font-size: #{rem(12px)};
  --button-small-icon-size: #{rem(16px)};
  --button-small-border-radius: var(--button-border-radius);

  --button-large-border-radius: var(--button-border-radius);
  --button-large-height: 38px;
  --button-large-padding: 0 #{$grid-space-3};

  --button-plain-height: 20px;

  // PRIMARY
  --button-primary-font-color: white;
  --button-primary-background-color: var(--primary-color-500);
  --button-primary-icon-color: var(--button-primary-font-color);
  --button-primary-spinner-color: var(--button-primary-font-color);
  --button-primary-hover-background-color: var(--primary-color-800);
  --button-primary-disabled-font-color: white;
  --button-primary-disabled-background-color: var(--primary-color-100);

  // SECONDARY
  --button-secondary-font-color: var(--black);
  --button-secondary-background-color: var(--grey-080);
  --button-secondary-icon-color: var(--grey-600);
  --button-secondary-spinner-color: var(--grey-400);
  --button-secondary-hover-background-color: var(--grey-180);
  --button-secondary-disabled-font-color: var(--grey-400);
  --button-secondary-disabled-background-color: var(--grey-050);

  // PLAIN
  --button-plain-font-color: var(--teal-500);
  --button-plain-spinner-color: var(--teal-300);
  --button-plain-hover-background-color: var(--teal-800);

  --button-plain-dark-font-color: var(--black);
  --button-plain-dark-icon-color: var(--grey-600);
  --button-plain-dark-spinner-color: var(--grey-400);
  --button-plain-dark-hover-background-color: var(--teal-800);

  --button-group-height: var(--button-large-height);
  --button-group-font-size: var(--button-font-size);
}
