@use 'sass:math';

@import '../variables';
@import '../mixins';

button:not(.selector-btn),
.button:not(.selector-btn) {
  @include button-base;
  @include button-medium-size;
  @include button-secondary;

  &.small {
    @include button-small-size;
  }

  &.medium {
    @include button-medium-size;
  }

  &.large {
    @include button-large-size;
  }

  &.primary {
    @include button-primary;
  }

  &.secondary {
    @include button-secondary;
  }

  &.caution {
    @include button-caution;
  }

  &.destructive {
    @include button-destructive;
  }

  &.outline {
    @include button-outline;

    &.checked {
      @include button-outline-checked;
    }
  }

  &.plain {
    @include button-plain;
  }
}

.btn-group {
  display: inline-flex;

  &:not(.outline) {
    position: relative;
    // btn group has a single size
    height: var(--button-group-height);
    box-sizing: border-box;

    &.round-radius {
      padding: $grid-space-1;
      border-radius: 20px;
      gap: $grid-space-1;

      button,
      .button {
        border-radius: 15px;
      }
    }

    &:not(.round-radius) {
      padding: 3px;
      border-radius: 5px;

      button,
      .button {
        border-radius: 3px;
      }
    }

    &:not(.white) {
      background-color: var(--grey-050);

      .button,
      button {
        &:not(:disabled) {
          &.active,
          &:active {
            background-color: #fff;
          }
        }
      }
    }

    &.white {
      background-color: #fff;

      .button,
      button {
        &:not(:disabled) {
          &.active,
          &:active {
            background-color: var(--grey-050);
          }
        }
      }
    }

    .button,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px transparent;
      background-color: transparent;
      color: var(--black);
      height: 100%;
      line-height: 1;
      font-family: inherit;
      font-size: var(--button-group-font-size);

      &:not(:last-child) {
        margin-right: math.div($grid-space-1, 2);
      }

      &:not(:disabled) {
        &.active,
        &:active {
          color: var(--primary-color);
        }

        &.hover,
        &:hover {
          &:not(.active):not(:active) {
            background-color: transparent;
            color: var(--primary-color);
          }
        }
      }

      &:disabled {
        color: var(--grey-300);
        background-color: var(--grey-050);

        &::before {
          display: none !important;
        }
      }
    }
  }

  &.outline {
    border-radius: 4px;

    .button,
    button {
      border: solid var(--button-border-size) var(--grey-180);
      background-color: transparent;
      // btn group has a single size
      height: var(--button-group-height);
      color: var(--black);
      transition: all 300ms;

      position: relative;
      z-index: 0;

      &:not(:first-child) {
        margin-left: -1px;
      }

      &.active,
      &:active {
        color: var(--primary-color);
        background-color: var(--primary-color-050);
        border-color: var(--primary-color-300);
        z-index: 2;
      }

      &:hover:not(.active):not(:active) {
        color: var(--primary-color);
        border-color: var(--grey-300);
        z-index: 1;
        background-color: transparent;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
