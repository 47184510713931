@import '../variables/icon';

// Local size method which is on elements which don't need deep.
@mixin _icon-size($size) {
  .lefty-icon-i {
    font-size: $size;
  }
}

.lefty-icon {
  display: inline-flex;

  &.flip {
    .lefty-icon-i {
      transform: scaleX(-1);
    }
  }

  @include _icon-size($icon-default);

  &[size='x-small'] {
    @include _icon-size($icon-x-small);
  }

  &[size='small'] {
    @include _icon-size($icon-small);
  }

  &[size='medium'] {
    @include _icon-size($icon-medium);
  }

  &[size='large'] {
    @include _icon-size($icon-large);
  }

  &[size='x-large'] {
    @include _icon-size($icon-x-large);
  }

  &[size='xx-large'] {
    @include _icon-size($icon-xx-large);
  }
}

// keep `material-**` for compatibility reason
// as the name should also be changed on IcoMoon export
.lefty-icon-i {
  height: 1em;
  line-height: 1;
  width: 1em;
}

// Add the `baseline` attribute on the icon element if the icon needs to be
// aligned to the baseline.
//
// E.g. <lefty-icon class="baseline" icon="add"></lefty-icon>
//
// In a flex or inline layout, the baseline of this element by default is
// the bottom margin since the element doesn't have any displayed text to
// calculate the baseline from. Thus, the icon is always positioned too high
// relative to other baseline-positioned elements. Adding the `baseline`
// attribute to the icon HTML element will insert a shim text element that will
// implicitly set the icon baseline to the slightly below the center of the
// icon.
//
// This issue arises because the material icons extended font sets a descent
// value of 0, which means the baseline is set to the bottom of the icon.
.lefty-icon.baseline {
  align-items: center;

  &::before {
    content: '-';
    display: inline-block;
    width: 0;
    visibility: hidden;
  }

  .lefty-icon-i {
    // Set a bottom margin so icon baseline is near the expected position of a
    // font rather than through the middle. Use `em` to be icon
    // font-size-independent.
    margin-bottom: 0.1em;
  }
}
