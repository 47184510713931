@import '../variables';
@import '../mixins';

.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  label {
    @include form-label;
  }
}

.form-label {
  @include form-label;
}
