@import '../variables/progress';

.lefty-progress {
  display: inline-block;
  width: 100%;
  height: $progress-height;

  .progress-container {
    position: relative;
    height: 100%;
    background-color: $progress-background-color;
    overflow: hidden;

    :host-context([dir='rtl']) & {
      transform: scaleX(-1);
    }
  }

  .progress-container.indeterminate {
    background-color: $progress-indeterminate-color;
  }

  .progress-container.indeterminate > .secondary-progress {
    background-color: $progress-color;
  }

  /*! @noflip */
  .active-progress,
  .secondary-progress {
    transform-origin: left center;
    transform: scaleX(0);
    position: absolute;
    top: 0;
    transition: transform $progress-transition;
    right: 0;
    bottom: 0;
    left: 0;
    will-change: transform;
  }

  .active-progress {
    background-color: $progress-color;
  }

  .secondary-progress {
    background-color: $progress-color;
  }

  .progress-container.indeterminate.fallback > .active-progress {
    animation-name: indeterminate-active-progress;
    animation-duration: $progress-indeterminate-duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .progress-container.indeterminate.fallback > .secondary-progress {
    animation-name: indeterminate-secondary-progress;
    animation-duration: $progress-indeterminate-duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  /*! @noflip */
  @keyframes indeterminate-active-progress {
    0% {
      transform: translate(0%) scaleX(0);
    }

    25% {
      transform: translate(0%) scaleX(0.5);
    }

    50% {
      transform: translate(25%) scaleX(0.75);
    }

    75% {
      transform: translate(100%) scaleX(0);
    }

    100% {
      transform: translate(100%) scaleX(0);
    }
  }

  /*! @noflip */
  @keyframes indeterminate-secondary-progress {
    0% {
      transform: translate(0%) scaleX(0);
    }

    60% {
      transform: translate(0%) scaleX(0);
    }

    80% {
      transform: translate(0%) scaleX(0.6);
    }

    100% {
      transform: translate(100%) scaleX(0.1);
    }
  }
}
