@import './fonts';
@import './grid';

@mixin lefty-ai-variables {
  --ai-badge-height: 20px;
  --ai-badge-padding: #{$grid-space-1};
  --ai-badge-gap: #{$grid-space-1};
  --ai-badge-font-size: var(--caption-1-font-size);
  --ai-badge-font-weight: 500;
  --ai-badge-line-height: 1;
  --ai-badge-border-size: 1px;
  --ai-badge-border-radius: 4px;
  --ai-badge-gradient: linear-gradient(
    124deg,
    #0096ff 0%,
    #bb64ff 50%,
    #f55273 100%
  );

  --ai-basic-tooltip-width: 400px;
  --ai-basic-tooltip-gap: #{$grid-space-2};
  --ai-basic-tooltip-padding: #{$grid-space-2} #{$grid-space-3};
  --ai-basic-tooltip-content-font-color: var(--grey-600);
}
