@import './grid';
@import './colors';

$_x-large-default-padding: 50px;
$dialog-x-large-width: 1140px;
$dialog-large-width: 740px;
$dialog-medium-width: 660px;
$dialog-small-width: 460px;
$dialog-x-small-width: 360px;

$dialog-top-margin: $grid-space-6;
$dialog-bottom-margin: $grid-space-6;

// content
$dialog-content-padding-horizontal: $grid-space-5;
$dialog-content-padding-vertical: $grid-space-5;
$x-large-dialog-content-padding-horizontal: $_x-large-default-padding;

// header
$dialog-header-title-padding-vertical: $grid-space-4;
$dialog-header-title-padding-horizontal: $grid-space-4;
$dialog-header-title-text-padding: $grid-space-2;

$dialog-header-description-padding-horizontal: $dialog-content-padding-horizontal;
$dialog-header-description-padding-vertical: $grid-space-5;

$x-large-dialog-header-padding-top: $_x-large-default-padding;

$x-large-dialog-header-padding-horizontal: $_x-large-default-padding;

// footer
$dialog-footer-padding-horizontal-compact: $dialog-content-padding-horizontal;
$dialog-footer-padding-vertical-compact: $grid-space-3;

$dialog-footer-padding-horizontal: $dialog-content-padding-horizontal;
$dialog-footer-padding-vertical: $grid-space-5;

$dialog-background: rgba(0, 0, 0, 0.5);
$dialog-shadow:
  0px 24px 38px 3px rgba(0, 0, 0, 0.14),
  0px 9px 46px 8px rgba(0, 0, 0, 0.12),
  0px 11px 15px -7px rgba(0, 0, 0, 0.2);
