@use 'sass:math';

@import '../variables';

.flex {
  display: flex;

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.column {
    flex-direction: column;

    &.reverse {
      flex-direction: column-reverse;
    }

    &.hCenter {
      align-items: center;
    }

    &.vCenter {
      justify-content: center;
    }

    &.space-between {
      align-items: space-between;
    }
  }

  &.row {
    &.reverse {
      flex-direction: row-reverse;
    }

    &.hCenter {
      justify-content: center;
    }

    &.vCenter {
      align-items: center;
    }

    &.space-between {
      justify-content: space-between;
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

.fill-space,
.flex-1 {
  flex: 1;
}

@mixin _content-grid-fit-width {
  width: 100%;
  max-width: none;
  padding-right: $grid-layout-padding;
  padding-left: $grid-layout-padding;
}

@mixin _content-grid-col($number) {
  width: 100%;
  max-width: $number * $gl-width + ($number - 1) * $gl-gutter;
}

/// Layout class following design styleguide
/// https://docs.google.com/presentation/d/1lyELpUxAno7hjUKl6J47qSaGoqTNmQFx9lREyLf6JKw/edit#slide=id.g6c98445ee1_0_0
.lefty-layout {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 120px;

  // default layout is on 3 columns width
  @include _content-grid-col(3);

  // large layout is on 4 columns width (discover)
  &.large {
    @include _content-grid-col(4);
  }

  // take full width of the screen
  &.wide {
    @include _content-grid-fit-width;
  }

  &.no-padding-bottom {
    padding-bottom: 0 !important;
  }

  &.mobile {
    padding-right: $grid-space-4 !important;
    padding-left: $grid-space-4 !important;
  }
}

.page-header {
  display: flex;
  align-items: center;

  /// special case here
  /// 50px is not in our defined grid but this is the only place where we use it
  margin-top: 50px;
  margin-bottom: $grid-space-5;

  &.mobile {
    margin-top: $grid-space-5 !important;
  }

  &.heading-1 {
    margin-top: $grid-space-7;
    margin-bottom: $grid-space-6;
  }

  .actions {
    .action {
      margin-left: $grid-space-2;
    }
  }

  // Add margin bottom to keep the ghost animation align with
  // the text in h1 and h2
  //
  // For esthetic reason we don't use the same height on ghost animation
  // so we need to play with margin
  &.loading > .label {
    // h2,.label font size is 24px
    // ghost height is 20px
    margin-bottom: 4px;
  }
}

.page-subheader {
  display: flex;
  margin-bottom: $grid-space-6;

  &.media-list {
    margin-bottom: $grid-space-5;
  }

  &:not(.columns) {
    align-items: center;
  }

  &.columns {
    flex-direction: column;

    .first-row {
      display: flex;
    }
  }

  .actions {
    .action {
      margin-left: $grid-space-2;
    }
  }
}

.page-header,
.page-subheader {
  & > h1,
  & > h2,
  & > h3 {
    text-transform: capitalize;
  }

  &.loading > .label,
  &.loading > h1,
  &.loading > h2,
  &.loading > h3 {
    @include ghost-animation;
    border-radius: 6px;
    height: 20px;
    width: 200px;
  }

  &.loading > h1 {
    // h1 font size is 30px
    // ghost height is 20px
    margin-bottom: $grid-space-1;
    margin-top: $grid-space-1;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }
}

// when screen width is less than 1590px
@include bp('lg') {
  .lefty-layout {
    // compact layout goes on 2 columns width
    &.compact {
      @include _content-grid-col(2);
    }

    // responsive layout fit to the available width
    &.large,
    &.compact {
      &.responsive {
        @include _content-grid-fit-width;
      }
    }
  }
}

// when screen width is less than 1200px
@include bp('md') {
  .lefty-layout {
    // default layout goes on 2 columns width
    @include _content-grid-col(2);

    // compact layout or any responsive layout take full width
    &.responsive,
    &.compact {
      @include _content-grid-fit-width;
    }

    // large layout goes on 3 columns width
    &.large:not(.responsive) {
      @include _content-grid-col(3);
    }
  }
}

// when screen width is less than 810px
@include bp('xs') {
  .lefty-layout {
    // default layout take full width
    @include _content-grid-fit-width;

    // large layout goes on 2 columns width
    &.large:not(.responsive) {
      @include _content-grid-col(2);
    }
  }
}

[#{$gl-attributeName}~='#{$gl-gridName}'],
[#{$gl-attributeName}*='#{$gl-gridName}-'],
[#{$gl-attributeName}*='#{$gl-gridName}_'] {
  &.cards-grid > {
    [#{$gl-attributeName}~='#{$gl-colName}'],
    [#{$gl-attributeName}*='#{$gl-colName}-'],
    [#{$gl-attributeName}*='#{$gl-colName}_'] {
      padding: 0 math.div($gl-gutter, 2) $gl-gutter;
    }
  }

  &.compact >,
  &.mobile > {
    [#{$gl-attributeName}~='#{$gl-colName}'],
    [#{$gl-attributeName}*='#{$gl-colName}-'],
    [#{$gl-attributeName}*='#{$gl-colName}_'] {
      padding: 0 math.div($grid-space-4, 2) $grid-space-4;
    }
  }
}
