$font: var(--font);
$font-bold: var(--font-bold);
$font-semi-bold: var(--font-semi-bold);
$font-light: var(--font-light);
$font-italic: var(--font-italic);
$font-regular: var(--font-regular);

$fancy-font: var(--fancy-font);

$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@mixin lefty-fonts-variables {
  --font: 'ProximaNova';
  --font-bold: 'ProximaNova Bold';
  --font-semi-bold: 'ProximaNova Semi Bold';
  --font-light: 'ProximaNova Light';
  --font-italic: 'ProximaNova Italic';
  --font-regular: 'ProximaNova Regular';
  --fancy-font: 'Butler-Black';

  --default-font: var(--font-regular);

  --heading-font-family: var(--font-regular);
  --heading-font-weight: normal;
  --heading-line-height: 110%;
  --heading-font-color: black;

  --heading-1-font-size: #{rem(30px)};
  --heading-1-font-weight: normal;

  --heading-2-font-size: #{rem(24px)};
  --heading-2-font-weight: normal;

  --heading-3-font-size: #{rem(20px)};
  --heading-3-font-weight: normal;

  --heading-4-font-size: #{rem(16px)};
  --heading-4-font-weight: normal;

  --heading-5-font-size: #{rem(16px)};
  --heading-5-font-weight: normal;

  --heading-6-font-size: #{rem(16px)};
  --heading-6-font-weight: normal;

  --body-1-font-family: var(--font-light);
  --body-1-font-weight: 400;
  --body-1-line-height: 1;
  --body-1-font-size: #{rem(14px)};
  --body-1-font-color: black;

  --caption-1-font-family: var(--font-light);
  --caption-1-font-weight: 400;
  --caption-1-line-height: 1;
  --caption-1-font-size: #{rem(12px)};
  --caption-1-font-color: black;

  --paragraph-x-large-font-family: var(--font-light);
  --paragraph-x-large-font-size: #{rem(12px)};
  --paragraph-x-large-line-height: 140%;
  --paragraph-x-large-font-weight: 400;
  --paragraph-x-large-font-color: black;

  --paragraph-large-font-family: var(--font-light);
  --paragraph-large-font-size: #{rem(16px)};
  --paragraph-large-line-height: 140%;
  --paragraph-large-font-weight: 400;
  --paragraph-large-font-color: black;

  --paragraph-medium-font-family: var(--font-light);
  --paragraph-medium-font-size: #{rem(14px)};
  --paragraph-medium-line-height: 140%;
  --paragraph-medium-font-weight: 400;
  --paragraph-medium-font-color: black;

  --paragraph-small-font-family: var(--font-light);
  --paragraph-small-font-size: #{rem(12px)};
  --paragraph-small-line-height: 140%;
  --paragraph-small-font-weight: 400;
  --paragraph-small-font-color: var(--grey-600);
}
