@import './colors';
@import './grid';

$slider-color: $primary-color;
$slider-outline-color: $primary-color-300;
$slider-unset-color: $grey-180;
$slider-thumb-disabled-color: $grey-400;

$slider-outline-size: 2px;
$slider-thumb-unactive-size: 16px;
$slider-thumb-active-size: 20px;
$slider-bar-height: 2px;
